import React, {createContext, useCallback, useContext, useState} from "react"
import useAxios from "axios-hooks";
import {url} from "../../helpers";

const MappingValidationContext = createContext(
    {results: [], loading: false, show: false, validate: () => null, onClose: () => null}
);

const useFetchValidationResults = () => {
    // const [{data: results, loading}, refetch] = useAxios({
    //     url: url("/expected_trade_sync/differing/"),
    //     manual: true,
    // });
    const results = [], loading = false, refetch = null
    return {results, loading, refetch};
}

export const useValidationState = () => {
    const {results, loading, refetch} = useFetchValidationResults();
    const [show, setShow] = useState(false);
    const onClose = useCallback(() => { setShow(false); }, [setShow])
    const validate = useCallback(() => {
        refetch();
        setShow(true);
    }, [refetch, setShow]);

    return {results, loading, show, validate, onClose}
}

const MappingValidationContextProvider = ({children}) => {
    const validation = useValidationState();
    return <MappingValidationContext.Provider value={validation}>{children}</MappingValidationContext.Provider>
}
const useMappingValidation = () => {
  const context = useContext(MappingValidationContext)
  if (context === undefined) {
    throw new Error('useMappingValidation must be used within a MappingValidationContextProvider')
  }
  return context
}

export { MappingValidationContextProvider, useMappingValidation}