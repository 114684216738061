import { useContext } from "react";

import { capitalize, formatNumber, isValidNumber } from "../../helpers";

import TableInput from "../TableInput";
import RequestViewContext from "../RequestContext";

export const currency_symbol_mapping = {
  EUR: "€",
  USD: "$",
  GBP: "£",
};

const EditableCell = ({
  tradeIds,
  field,
  value,
  render,
  allowNegativeOrNull,
  accountingPeriodClosed,
}) => {
  const { update, disableEditing } = useContext(RequestViewContext);

  const onChange = (value) => {
    tradeIds.forEach((tradeId) => {
      update(value, tradeId);
    });
  };

  // Determine if editing should be disabled based on accountingPeriodClosed
  const isEditingDisabled = accountingPeriodClosed ? true : disableEditing;

  return (
    <>
      <TableInput
        required
        tabbable={!isEditingDisabled}
        enabled={!isEditingDisabled}
        value={value}
        key={tradeIds + value}
        isInvalid={(str) =>
          isValidNumber(str, !allowNegativeOrNull, allowNegativeOrNull || false)
        }
        onChange={async (value) => {
          // Only allow editing if not disabled
          if (!isEditingDisabled) {
            onChange({
              [field]: `${value}`,
            });
          }
          return;
        }}
        id={tradeIds.join(",") + "." + field}
        formatContents={(value) => {
          return render ? render(value) : value || "-";
        }}
      />
    </>
  );
};
const editable = (
  types,
  field,
  render,
  allowNegativeOrNull,
  noHighlight = true,
) => {
  return (value, data) => {
    return {
      props: noHighlight
        ? {}
        : {
            style: {
              backgroundColor: "rgba(191, 202, 215, 0.2)",
            },
          },
      children: (
        <EditableCell
          tradeIds={types.map((type) => data[type + "_trade_id"])}
          value={value}
          field={field}
          render={render}
          allowNegativeOrNull={allowNegativeOrNull}
          accountingPeriodClosed={data.accounting_period_closed}
        />
      ),
    };
  };
};

const numericRender = (value, decimals) => {
  return formatNumber(value, true, decimals);
};

export const numericRenderBuilder = (decimals) => {
  return (value) => numericRender(value, decimals);
};

const commonFirst = [
  {
    title: "Maturities",
    fixed: "left",
    dataIndex: "maturity",
    render: capitalize,
  },
  {
    title: "Buy/Sell",
    dataIndex: "action",
  },
  {
    title: (
      <>
        Quantity
        <br />
        (mt)
      </>
    ),
    dataIndex: "quantity",
    render: numericRenderBuilder(2),
  },
];

const final_price = [
  {
    title: (
      <>
        Final Price
        <br />
        ($/mt)
      </>
    ),
    dataIndex: "final_price",
    render: numericRenderBuilder(2),
  },
];

const LMEAdjusted = (
  currency,
  currencySymbol,
  as,
  isFixingSpread = false,
  isEditable = true,
) => ({
  title: (
    <>
      {as || "LME adjusted"}
      <br />({currencySymbol || "USD"}/mt)
    </>
  ),
  dataIndex: "lme_adjusted_" + currency.toLowerCase(),
  render:
    isEditable && currency === "USD"
      ? editable(
          ["lme"],
          "price",
          (value) =>
            isFixingSpread && value === 0 ? 0 : formatNumber(value, true, 2),
          isFixingSpread,
        )
      : (value) =>
          as === "Cost" && value === 0 ? 0 : formatNumber(value, true, 2),
});

const FixingSpread = (currency, currencySymbol) => {
  return LMEAdjusted(currency, currencySymbol, "Fixing Spread", true);
};

const QTID = (type, as) => ({
  title: (
    <>
      Fusion ID
      <br />
      {as === false ? "" : `(${as || type})`}
    </>
  ),
  dataIndex: type.toLowerCase() + "_qt",
  render: (value, record) => {
    return <span>{value || ''}</span>
  }
});



const MarketPremium = (
  currency,
  currencySymbol,
  unit = false,
  isEditable = true,
) => ({
  title: (
    <>
      Market premium
      <br />({unit ? unit : `${currencySymbol || "USD"}/mt`})
    </>
  ),
  dataIndex: "mkp_adjusted_" + currency.toLowerCase(),
  render:
    isEditable && currency === "USD"
      ? editable(["mkp"], "price")
      : (value) => value,
});

const TotalPrice = (currency, currencySymbol) => ({
  title: `Total Price (${currencySymbol || "USD"}/mt)`,
  dataIndex: "total_price_" + currency.toLowerCase(),
  render: numericRender,
});

// TODO: update depending on whether or not we keep the total price
void TotalPrice;

const CurrencyForwardRate = (currency, currencySymbol, isEditable = true) => ({
  title: (
    <>
      {currencySymbol}/USD
      <br />
      Forward
    </>
  ),
  dataIndex: "currency_forward_rate",
  render: isEditable
    ? editable(["lme", "mkp"], "forward_rate", numericRenderBuilder(4), true)
    : numericRenderBuilder(4),
});

const columns = (request) => {
  const currency = request.currency;
  const isForeignCurrency = currency !== "USD";

  const isSpot = request.delivery_months === "Spot";

  const isLMEOnly = request.request_maturities[0].trades.length === 1;

  const map = {
    Quote: [
      ...commonFirst,
      LMEAdjusted("USD", "$"),
      ...(isSpot ? [] : [QTID("LME")]),
      ...(isLMEOnly ? [] : [MarketPremium("USD", "$")]),
      ...(isSpot || isLMEOnly ? [] : [QTID("MKP")]),
      // TotalPrice("USD"),
      ...(isForeignCurrency
        ? [
            CurrencyForwardRate("currency", currency),
            LMEAdjusted(
              "currency",
              currency_symbol_mapping[currency] || currency,
            ),
            ...(isLMEOnly
              ? []
              : [
                  MarketPremium(
                    "currency",
                    currency_symbol_mapping[currency] || currency,
                  ),
                ]),
            // TotalPrice("currency", currency),
          ]
        : []),
    ],
    "Auto Quote": [
      ...commonFirst,
      LMEAdjusted(
        "USD",
        "$",
        ["Postponement Auto Quote", "Anticipation Auto Quote"].includes(
          request.request_type,
        )
          ? "Cost"
          : "",
        false,
        false,
      ),
      ...(isSpot ? [] : [QTID("LME")]),
      ...(isLMEOnly
        ? []
        : [
            MarketPremium(
              "USD",
              "$",
              false,
              ["Open", "For correction"].includes(request.status)
                ? true
                : request.request_type !== "Postponement Auto Quote" &&
                    request.request_type !== "Anticipation Auto Quote" &&
                    request.request_type !== "Auto Quote",
            ),
          ]),
      ...(isSpot || isLMEOnly ? [] : [QTID("MKP")]),
      // TotalPrice("USD"),
      ...(isForeignCurrency
        ? [
            CurrencyForwardRate("currency", currency, false),
            LMEAdjusted(
              "currency",
              currency_symbol_mapping[currency] || currency,
              "",
              false,
              false,
            ),
            ...(isLMEOnly
              ? []
              : [
                  MarketPremium(
                    "currency",
                    currency_symbol_mapping[currency] || currency,
                    false,
                    ["Open", "For correction"].includes(request.status)
                      ? true
                      : request.request_type !== "Postponement Auto Quote" &&
                          request.request_type !== "Anticipation Auto Quote" &&
                          request.request_type !== "Auto Quote",
                  ),
                ]),
            // TotalPrice("currency", currency),
          ]
        : []),
    ],
    "Indic Quote": [
      ...commonFirst,
      LMEAdjusted("USD", "$", "", false, false),
      ...(isLMEOnly
        ? []
        : [
            MarketPremium(
              "USD",
              "$",
              false,
              ["Open", "For correction", "Filled"].includes(request.status),
            ),
          ]),
      // TotalPrice("USD"),
      ...(isForeignCurrency
        ? [
            CurrencyForwardRate("currency", currency, false),
            LMEAdjusted(
              "currency",
              currency_symbol_mapping[currency] || currency,
              "",
              false,
              false,
            ),
            ...(isLMEOnly
              ? []
              : [
                  MarketPremium(
                    "currency",
                    currency_symbol_mapping[currency] || currency,
                    false,
                  ),
                ]),
            // TotalPrice("currency", currency),
          ]
        : []),
    ],
    "Limit Order": [
      ...commonFirst,
      LMEAdjusted("USD", "$"),
      ...(isSpot ? [] : [QTID("LME")]),
      ...(isLMEOnly ? [] : [MarketPremium("USD", "$")]),
      ...(isSpot || isLMEOnly ? [] : [QTID("MKP")]),
      // TotalPrice("USD"),
      ...(isForeignCurrency
        ? [
            CurrencyForwardRate("currency", currency),
            LMEAdjusted(
              "currency",
              currency_symbol_mapping[currency] || currency,
            ),
            ...(isLMEOnly
              ? []
              : [
                  MarketPremium(
                    "currency",
                    currency_symbol_mapping[currency] || currency,
                  ),
                ]),
          ]
        : // TotalPrice("currency", currency),
          []),
    ],
    CSP: [
      ...commonFirst,
      LMEAdjusted("USD", "$"),
      ...(isSpot ? [] : [QTID("LME")]),
      ...(isLMEOnly ? [] : [MarketPremium("USD", "$")]),
      ...(isSpot || isLMEOnly ? [] : [QTID("MKP")]),
      // TotalPrice("USD"),
      ...(isForeignCurrency
        ? [
            CurrencyForwardRate("currency", currency),
            LMEAdjusted(
              "currency",
              currency_symbol_mapping[currency] || currency,
            ),
            ...(isLMEOnly
              ? []
              : [
                  MarketPremium(
                    "currency",
                    currency_symbol_mapping[currency] || currency,
                  ),
                ]),
            // TotalPrice("currency", currency),
          ]
        : []),
    ],
    APC: [
      ...commonFirst,
      FixingSpread("USD", "$"),
      ...(isSpot ? [] : [QTID("LME", false)]),
      ...(isLMEOnly ? [] : [MarketPremium("USD", "$")]),
      ...(isSpot || isLMEOnly ? [] : [QTID("MKP")]),
      ...final_price
    ],
    "At Market": [
      ...commonFirst,
      MarketPremium("USD", "$"),
      ...(isSpot ? [] : [QTID("MKP")]),
      // TotalPrice("USD"),
      ...(isForeignCurrency
        ? [
            CurrencyForwardRate("currency", currency),
            MarketPremium(
              "currency",
              currency_symbol_mapping[currency] || currency,
            ),
            // TotalPrice("currency", currency),
          ]
        : []),
    ],
  };
  const requestType = [
    "Postponement Auto Quote",
    "Anticipation Auto Quote",
    "Auto Quote",
    "Indic Quote",
  ].includes(request.request_type)
    ? "Auto Quote"
    : request.request_type;
  return map[requestType];
};

export default columns;
