import SimpleTable from "../SimpleTable";
import RequestVWP from "../RequestVWP";
import { formatDate, formatNumber, renameInstrument } from "../../helpers";
import React, {useState} from "react";
import { DateRangeInput } from "rt-design-system-backup";
import { dateFormat } from "../Views/Maturities";
import Form, { FormFooter, InputGroup } from "../Form";


const RequestPricingInstructions = ({ request, editable, onPricingPeriodChange, setNewContractType }) => {
  const trades =
    request.request_maturities &&
    request.request_maturities[0] &&
    request.request_maturities[0].trades;
  const instrument =
    trades && trades.map((trade) => trade.instrument.name).join(" + ");


  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const createDateWithoutTime = (str) => {
    const date = new Date(Date.parse(str));
    date.setHours(0, 0, 0, 0);
    return date;
  };

  // Initialize state with parsed dates
  const [dateRange, setDateRange] = useState({
    start: request.pricing_period_start_date ? createDateWithoutTime(request.pricing_period_start_date) : null,
    end: request.pricing_period_end_date ? createDateWithoutTime(request.pricing_period_end_date) : null
  });

  const getMaxDate = () => {
    if (dateRange.start) {
      // If a start date is selected, the max date is two years from the start date
      return new Date(dateRange.start.getFullYear() + 2, dateRange.start.getMonth(), dateRange.start.getDate());
    } else {
      return new Date();
    }
  };

  const handleDateRangeChange = (newRangeArray) => {
    let adjustedStart = newRangeArray[0];
    const newEnd = newRangeArray[1];
  
  
    setDateRange({ start: adjustedStart, end: newEnd });
    onPricingPeriodChange(adjustedStart, newEnd);
  };
 
  const contractTypeArray = [
    { name: "(None)", value: "" },
    { name: "APAC Spot", value: "APAC Spot" },
    { name: "APAC M+1", value: "APAC M+1" }
  ]

  return (
    <>
      <h4>Pricing Instructions</h4>
      <SimpleTable style={{ flex: "1 0 300px" }}>
        <tr>
          <td>Instrument:</td>
          <td>{renameInstrument(instrument)}</td>
        </tr>
        <tr>
          <td>Total quantity (mt):</td>
          <td>{formatNumber(request.total_quantity, false, 2)}</td>
        </tr>
        {request.delivery_months?
          <tr>
            <td>Delivery:</td>
            <td>{request.delivery_months}</td>
          </tr>:
            null
        }
        {!["Anticipation Auto Quote", "Postponement Auto Quote"].includes(request.request_type) ? (
        <tr>
          <td>Contract type:</td>
          <td>
            {editable ? (
              <InputGroup
                required={false}
                type="select"
                name="contract_type"
                defaultValue={[request.contract_type]}
                onChange={(value) => {
                  setNewContractType(value[0])
                }}
                items={contractTypeArray.map((item) => {
                  return {
                    name: item.name,
                    value: item.value,
                  };
                })}
              />
            ) : (
              request.contract_type ? request.contract_type : "-"
            )}
          </td>
        </tr>
        ) : null
        }
          {["APC", "CSP", "Limit Order"].indexOf(request.request_type) >= 0 ? (
          <tr>
            <td>Pricing basis:</td>
            <td>{request.benchmark}</td>
          </tr>
        ) : null}
        {request.request_type === "Limit Order" ? (
          <tr>
            <td>Target price:</td>
            <td>{request.requested_price}</td>
          </tr>
        ) : null}
        {["Quote", "Auto Quote", "Indic Quote", "APC", "Postponement Auto Quote", "Anticipation Auto Quote"].indexOf(request.request_type) < 0 ? (
          <RequestVWP embedded request={request} editable={editable} />
        ) : null}
        {["Quote", "Auto Quote", "Indic Quote", "Postponement Auto Quote", "Anticipation Auto Quote"].includes(request.request_type) ? (
          <tr>
            <td>Quote validity:</td>
            <td>{request.validity}</td>
          </tr>
        ) : null}
        {(["Limit Order"].includes(request.request_type) && request.valid_until) ? (
          <tr>
            <td>Valid until:</td>
            <td>{formatDate(request.valid_until, request.request_type !== "Auto Quote", true)}</td>
          </tr>
        ) : null}
        {["Auto Quote", "Postponement Auto Quote", "Anticipation Auto Quote"].includes(request.request_type) ? (request.accepted_at ?
          <tr>
            <td>Accepted:</td>
            <td>
              {
                formatDate(request.accepted_at, false)
              }
            </td>
          </tr> :
            <tr>
              <td>Valid until:</td>
              <td>{formatDate(request.valid_until, request.request_type !== "Auto Quote")}</td>
            </tr>
        ) : null}
        {["APC", "CSP"].indexOf(request.request_type) >= 0 ? (
          <tr>
            <td>Pricing period:</td>
            <td>
              {request.request_type === "APC" && editable && request.status === 'Open' ? (
               <DateRangeInput
               singleMonthOnly
               startInputProps={{ name: "pricing_period_start_date" }}
               endInputProps={{ name: "pricing_period_end_date" }}
              {...dateFormat}
               value={[dateRange.start, dateRange.end]}
               onChange={handleDateRangeChange}
               maxDate={getMaxDate()}
               required
               key="pricing_period"
             />
              ) : (
                <>
                  {request.pricing_period_start_date
                      ? formatDate(request.pricing_period_start_date, true, true) + " to "
                      : ""}
                  {formatDate(request.pricing_period_end_date, true, true)}
                </>
              )}
            </td>
          </tr>
        ) : null}
        {["Quote", "Auto Quote", "Indic Quote", "Limit Order", "CSP"].indexOf(request.request_type) >= 0 && request.wed_to_full_avg !== null ? (
          <tr>
            <td>3<sup>rd</sup> Wed to Full Avg:</td>
            <td>
              {request.wed_to_full_avg === 0 ? '-' : `USD ${request.wed_to_full_avg}` }
            </td>
          </tr>
        ) : null}
      </SimpleTable>
    </>
  );
};

export default RequestPricingInstructions;
