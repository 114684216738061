import {formatDate, replaceOrderTypes} from "../../helpers";

import SimpleTable from "../SimpleTable";
import RequestTimer from "../RequestTimer";
import React from "react";
import StatusTag from "../../core/Tags";
import {Tooltip} from "antd";
import {Icon, DateInput} from "rt-design-system-backup";
import Select from "../Select";


const RequestOverview = ({request, showSpinner, editable=false, customers=[], salesEntity=[],
    setCustomer=null, setSalesEntity=null, date,
    setDate,
    dateFormat,
    today,
    isViewing = true
}) => {
  return (
    <div>
      <h4 style={{fontSize: '24px', lineHeight: '32px'}}>Request #{request.id}</h4>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <SimpleTable style={{ flex: "0 0 auto", fontSize: '14px', lineHeight: '22px'}}>
          <tr>
            <td>Type:</td>
            <td>{replaceOrderTypes(request.request_type)}</td>
          </tr>
          <tr>
            <td>Status:</td>
            <td>
              <StatusTag status={request.status}/>
              {request.status === "For correction" ?
                <Tooltip placement="right" title={request.correction_comments} color={'#00397A'} defaultVisible overlayInnerStyle={{borderRadius: 16}} zIndex={1}>
                  <Icon style={{marginLeft: 10, marginRight: -4, color: '#00397A'}} icon={'info-sign'}/>
                </Tooltip>
                : null
              }
            </td>
          </tr>
          <tr>
            <td>Created on:</td>
            <td>{formatDate(request.created_at)}</td>
          </tr>
          <tr>
            <td>Requestor:</td>
            <td>{request.requestor.full_name}</td>
          </tr>
          <tr>
            <td>Trader:</td>
            <td>{(request.trader && request.trader.full_name) || '-'}</td>
          </tr>
          <tr>
            <td>Sales entity:</td>
            {(editable && ((request.status === "For correction") && (!request.request_type) )) ?
              <td>
                <Select
                  defaultValue={[request.sales_entity.id]}
                  onChange={(value)=>{value[0] && setSalesEntity(value[0].value)}}
                  items={salesEntity.map(x=>({name:x["name"], value:x["id"]}))}
                />
              </td> :
              <td>{request.sales_entity.name}</td>
            }
          </tr>
          <tr>
            <td>Customer/Supplier:</td>
            {editable && request.request_type !== 'Indic Quote' ?
              <td>
                <Select
                  defaultValue={[request.customer.id]}
                  onChange={(value)=>{value[0] && setCustomer(value[0].value)}}
                  items={customers.map(x=>({name:x["display_name"], value:x["id"]}))}
                />
              </td> :
              <td>{request.customer && request.customer.display_name}</td>
            }

          </tr>
          <tr>
            <td>Currency:</td>
            <td>{request.currency}</td>
          </tr>
          {
            !isViewing ? ( // Only display Trade Date when editing or filling request
              <tr>
                <td>Trade Date:</td>
                <td>
                  <DateInput
                    inputProps={{ name: "filled_date" }}
                    formatDate={dateFormat.formatDate}
                    parseDate={dateFormat.parseDate}
                    maxDate={today}
                    required
                    placeholder={'Date'}
                    value={date}
                    onChange={setDate}
                  />
                </td>
              </tr>
            ) : (
              <tr>
              <td>Trade Date:</td>
              <td>
                {request.request_maturities?.[0]?.trades?.[0]?.filled_at? formatDate(request.request_maturities?.[0]?.trades?.[0]?.filled_at, true, true) : "-"}
              </td>
            </tr>
            )
          }

          {request.internal_portfolio && (
            <tr>
              <td>Internal Portfolio:</td>
              <td>{request.internal_portfolio}</td>
            </tr>
          )}
        </SimpleTable>
        {showSpinner &&
        ["Postponement Auto Quote", "Anticipation Auto Quote", "Auto Quote"].includes(request.request_type) ? (
          <div style={{ flex: "0 0 auto", paddingTop: '10px'}}>
            <RequestTimer validUntil={request.valid_until} validity={request.validity}
                            createdOn={request.updated_at} showProgress={true} status={request.status}/>
          </div>
        ) : (
          <span />
        )}
      </div>
    </div>
  );
};

export default RequestOverview;