import React, { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import Toaster from "../../Toaster";

import {
  Button,
  DateInput,
  DateRangeInput,
  FormGroup as RTFormGroup,
  Icon,
  TextArea,
} from "rt-design-system-backup";

import Form, { FormContext, FormFooter, InputGroup } from "../../Form";
import AppContext from "../../AppContext";
import { 
  capitalize, 
  getCSRF, 
  isValidNumber, 
  replaceOrderTypes, 
  url, 
  parseDateWithoutTimezone, 
  renameInstrument 
} from "../../../helpers";

import useLogin from "../../useLogin"

import { types } from "../NewRequest/types";

import "../NewRequest/style.scss";

const today = new Date();

const Column = styled.div`
  max-width: 450px;
`;

const dateFormat = {
  formatDate: (date) => {
    if (date === "null") return "";

    const y = "" + date.getFullYear();
    const m = "" + (date.getMonth() + 1);
    const d = "" + date.getDate();

    return y + "-" + m.padStart(2, "0") + "-" + d.padStart(2, "0");
  },
  parseDate: (str) => parseDateWithoutTimezone(str),
};

const TradeInputWrapper = styled.div`
  & > div {
    display: flex;
    flex-direction: row;

    .bp3-form-group {
      margin: 4px;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }

    .rtcmd-button-select {
      flex-wrap: nowrap;
    }
  }
`;

const TradeInput = ({ report, remove, id, maturities, data = {} }) => {
  const valid_maturity = data.maturity && maturities.map(maturity => maturity.value).includes(data.maturity.value)

  return (
    <TradeInputWrapper>
      <Form tag="div" onChange={report}>
        <InputGroup
          defaultValue={data.action ? [data.action.value] : ["Buy"]}
          required
          name="action"
          type="select"
          items={[
            { name: "Buy", value: "Buy" },
            { name: "Sell", value: "Sell" },
          ]}
        />
        <InputGroup
          required
          defaultValue={
            valid_maturity ? [data.maturity.value] : (maturities[0] ? [maturities[0].value] : undefined)
          }
          name="maturity"
          type="select"
          items={maturities} />
        <InputGroup
          isInvalid={(value) => {
            return isValidNumber(value, true);
          }}
          rightElement={<span style={{ color: '#002C5F' }}>mt</span>}
          required
          defaultValue={data.quantity ? data.quantity : undefined}
          placeholder="Quantity"
          name="quantity"
          type="number"
        />
        <div className="bp3-form-group rtcmd-request-form-icon" data-key={id}>
          <Icon onClick={id > 0 ? remove : void 0} icon="remove" />
        </div>
      </Form>
    </TradeInputWrapper>
  );
};

const TypeAwareNewRequestForm = ({ type, request = null }) => {
  const getTime = () => {
    return new Date().getTime()
  }
  const defaultTrade = {
    "id": getTime(),
  }
  const initialTrades = request.request_maturities.map((req_maturity) => {
    return {
      id: getTime(),
      data: {
        action: { name: req_maturity.trades[0].action, value: req_maturity.trades[0].action },
        maturity: { name: capitalize(req_maturity.maturity.shortened_string), value: req_maturity.maturity.id },
        quantity: req_maturity.trades[0].quantity,
        maturity_id: req_maturity.maturity.id,
      },
      valid: true,
      invalid: [],
    };
  });

  const [trades, setTrades] = useState(initialTrades);
  const { invalid, setFormContext, ...formContext } = useContext(FormContext);
  
  /** Acceptable time validities for quotes */
  const [{ loading: quoteValiditiesLoading, data: quoteValidities }] = useAxios(
    {
      url: url("/quote_validity/"),
    }
  );

  /** Acceptable time validities for requests */
  const [
    { loading: requestValiditiesLoading, data: requestValidities },
  ] = useAxios({
    url: url("/request_validity/"),
  });

  /** Benchmarks */
  const [{ loading: benchmarksLoading, data: benchmarks }] = useAxios({
    url: url("/request_benchmark_months/"),
  });

  /** Instruments */
  const [{ loading: instrumentsLoading, data: instruments }] = useAxios({
    url: url("/instrument/"),
  });

  /** Delivery months */
  const [{ loading: deliveryMonthsLoading, data: deliveryMonths }] = useAxios({
    url: url("/request_delivery_months/"),
  });

  /** Trade maturities */
  const [{ loading: tradeMaturitiesLoading, data: tradeMaturities }] = useAxios(
    {
      url: url("/maturity/?display_only_request"),
    }
  );

  const filterExistingMaturities = (maturities) => {
    const alreadySelectedMaturities =
      formContext.data &&
        formContext.data.trades &&
        formContext.data.trades.length
        ? formContext.data.trades
          .map((trade) => {
            if (trade && trade.maturity && trade.maturity.length)
              return trade.maturity[0].value;
            return false;
          })
          .filter((item) => item)
        : [];
    return maturities.filter((maturity) => {
      return alreadySelectedMaturities.indexOf(maturity.value) < 0;
    });
  };

  const updateTradesStatus = () => {
    let valid = true;
    let data = [];

    trades.forEach((trade) => {
      if (trade.valid !== true) {
        valid = false;
      }

      data.push(trade.data);
    });

    setFormContext((formContext) => {
      if (!valid && formContext.invalid.indexOf("trades") < 0) {
        return {
          invalid: [...formContext.invalid, "trades"],
        };
      } else if (valid && formContext.invalid.indexOf("trades" >= 0)) {
        return {
          invalid: [
            ...[...formContext.invalid].filter((eName) => eName !== "trades"),
          ],
        };
      }
      return {};
    });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    updateTradesStatus();
    setFormContext({
      data: {
        trades: trades.map((trade) => trade.data),
      },
    });
  }, [trades]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const report = (key) => {
    return (data) => {
      const tradesCopy = [...trades];
      const tradeId = tradesCopy[key].id
      tradesCopy[key] = data;
      tradesCopy[key]['id'] = tradeId;

      if (initialTrades[key] && initialTrades[key].data.maturity_id) {
        tradesCopy[key].data.maturity_id = initialTrades[key].data.maturity_id
      } else {
        delete tradesCopy[key].data.maturity_id
      }

      if (JSON.stringify(data) !== JSON.stringify(trades[key])) {
        setTrades(tradesCopy);
      }
    };
  };

  const remove = (key) => {
    return () => {
      const tradesCopy = [...trades].filter((_, itemKey) => itemKey !== key);
      setTrades(tradesCopy);
    };
  };

  const [{ data: salesEntities }] = useAxios({
    url: url("/sales_entity/"),
  });

  const [{ data: customers }] = useAxios({
    url: url("/customer/"),
  })

  const selectedSalesEntityId = formContext.data?.sales_entity?.[0]?.value;
  const selectedEntity = salesEntities?.find(
    entity => entity.id === selectedSalesEntityId
  );
  const salesEntityRegion = selectedEntity?.region;
  const selectedCustomerId = formContext.data?.customer?.[0]?.value;
  const selectedCustomer = customers?.find(
    customer => customer.id === selectedCustomerId
  );
  const customerContractPricing = selectedCustomer?.contract_pricing;

  const deliveryType = formContext.data?.delivery_months?.[0];

  useEffect(() => {
      setFormContext((formContext) => {
          console.log("salesEntityRegion", salesEntityRegion)
          console.log("customerContractPricing", customerContractPricing)
          console.log("deliveryType", deliveryType)
          // console.log("formContext", formContext)
          const changeTriggerValue = salesEntityRegion + customerContractPricing + deliveryType
          if(changeTriggerValue)
          {
              const getDefaultContractType = () => {
            if (!salesEntityRegion) {
              return [{ name: '(None)', value: '' }]; 
            }

            if (salesEntityRegion === 'APAC' && deliveryType  === 'Spot' && !customerContractPricing ) { 
              console.log(`When Sales Entity has Region “APAC”, and the Delivery selected is “Spot”, and the Customer/Supplier has Customer Pricing “Blank”, the Contract Type will be populated as “APAC Spot”.`)
              return [{ name: 'APAC Spot', value: 'APAC Spot' }];
            }

            if (salesEntityRegion === 'APAC' && deliveryType  === 'Future' && customerContractPricing === 'M+1' ) { 
              console.log(`When Sales Entity has region “APAC”, and the Delivery selected is “Future”, and the Customer/Supplier has Contract Pricing “M+1”, the Contract Type will be populated as “APAC M+1”.`)
              return [{ name: 'APAC M+1', value: 'APAC M+1' }];
            }
            
            return [{ name: '(None)', value: '' }];
        };
          const update = {
              data: {
                  ...formContext.data,
                  contract_type: getDefaultContractType(),
              },
          };
          return {...formContext, ...update};
      }else{
          return formContext
      }
      });
  }, [salesEntityRegion, customerContractPricing, deliveryType]);

  const loading =
    quoteValiditiesLoading ||
    requestValiditiesLoading ||
    benchmarksLoading ||
    instrumentsLoading ||
    deliveryMonthsLoading ||
    tradeMaturitiesLoading;

  return loading ? (
    "Loading..."
  ) : (
    <>
      <InputGroup
        required
        type="select"
        title="Instrument"
        name="instrument"
        key="instrument"
        defaultValue={[request.request_maturities[0].trades.map(trade => trade.instrument.name).join(":::")]}
        // defaultValue={["LME Aluminium"]}
        items={instruments
          .map((item) => {
            if (type === "At Market") {
              if (item[0][1])
                return {
                  name: renameInstrument(item[0][1]),
                  value: item[0][1],
                };
              return false;
            }
            return {
              name: renameInstrument(item[1]),
              value: item[0].join(":::"),
            };
          })
          .filter((item) => item)}
      />
      {["Quote", "Auto Quote"].includes(type) ? (
        <>
          <p style={{ color: '#706F6F' }}>Quote validity</p>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <InputGroup
              required
              type="select"
              name="validity"
              key="validity"
              defaultValue={request ? [request.validity] : undefined}
              items={quoteValidities.map((item) => {
                return {
                  name: item[0],
                  value: item[0],
                };
              })}
            />
            <div style={{ margin: '10px' }}></div>
            <FormContext.Consumer>
              {(ctx) =>
                ctx.data.validity &&
                  ctx.data.validity.length &&
                  ctx.data.validity[0].value === "Specific date" ? (
                  <DateInput
                    inputProps={{ name: "valid_until" }}
                    {...dateFormat}
                    defaultValue={request.valid_until ? parseDateWithoutTimezone(request.valid_until) : new Date()}
                    required
                    key="valid_until"
                  />
                ) : null
              }
            </FormContext.Consumer>
          </div>
        </>
      ) : null}
      {["CSP", "APC", "Limit Order"].indexOf(type) >= 0 ? (
        <InputGroup
          required
          type="select"
          title="Pricing basis"
          name="benchmark"
          key="benchmark"
          defaultValue={[request.benchmark]}
          onChange={(val) => {
            if (val.length && val[0].value === "Maturity") {
              setTrades(trades.slice(0, 1));
            }
          }}
          items={benchmarks
            .map((item) => {
              return {
                name: item[0],
                value: item[0],
              };
            })
            .filter((item) => {
              if (type === "CSP" || type === "APC") {
                return item.value !== "Maturity";
              }
              return true;
            })}
        />
      ) : null}
      {type === "Limit Order" ? (
        <>
          <InputGroup
            required
            type="number"
            title="Target price"
            key="target_price"
            name="requested_price"
            defaultValue={request.requested_price}
            isInvalid={(value) => {
              return isValidNumber(value, true);
            }}
          />
        </>
      ) : null}
      {type === "Limit Order" ? (
        <>
          <p style={{ color: '#706F6F' }}>Valid until</p>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <InputGroup
              required
              type="select"
              name="validity"
              key="validity"
              defaultValue={request.validity ? [request.validity] : undefined}
              items={requestValidities.map((item) => {
                return {
                  name: item[0],
                  value: item[0],
                };
              })}
            />
            <div style={{ margin: '10px' }}></div>
            <FormContext.Consumer>
              {(ctx) =>
                ctx.data.validity &&
                  ctx.data.validity.length &&
                  ctx.data.validity[0].value === "Good until specific date" ? (
                  <DateInput
                    inputProps={{ name: "valid_until" }}
                    {...dateFormat}
                    defaultValue={request.valid_until ? parseDateWithoutTimezone(request.valid_until) : new Date()}
                    required
                    key="valid_until"
                  />
                ) : null
              }
            </FormContext.Consumer>
          </div>
        </>
      ) : null}
      {["APC"].indexOf(type) >= 0 ? (
        <RTFormGroup label="Pricing period">
          <DateRangeInput
            singleMonthOnly
            startInputProps={{ name: "pricing_period_start_date" }}
            endInputProps={{ name: "pricing_period_end_date" }}
            {...dateFormat}
            defaultValue={[
              parseDateWithoutTimezone(request.pricing_period_start_date),
              parseDateWithoutTimezone(request.pricing_period_end_date),
            ]}
            maxDate={new Date(today.getFullYear()+5, today.getMonth(), today.getDate())}
            required
            key="pricing_period"
          />
        </RTFormGroup>
      ) : null}
      {["CSP"].indexOf(type) >= 0 ? (
        <RTFormGroup label="Pricing period">
          <DateInput
            inputProps={{ name: "pricing_period_end_date" }}
            {...dateFormat}
            defaultValue={parseDateWithoutTimezone(request.pricing_period_end_date)}
            required
            key="pricing_period_end"
          />
        </RTFormGroup>
      ) : null}
      <InputGroup
        required
        type="button-select"
        title="Delivery months"
        name="delivery_months"
        defaultValue={request.delivery_months ? [request.delivery_months] : undefined}
        key="delivery_months"
        onChange={(target) => {
          if (target[0] === "Spot") {
            setTrades(trades.slice(0, 1));
          } else if (initialTrades.length > 1) {
            setTrades(initialTrades);
          }
        }}
        items={deliveryMonths.map((item) => {
          return {
            name: item[1],
            value: item[0],
          };
        })}
      />
      <InputGroup
        key={formContext?.data?.type?.[0]?.value }
        required={false}
        type="select"
        name="contract_type"
        title="Contract Type"
        query={formContext?.data?.contract_type?.[0].name }
        items={[
          { name: "(None)", value: "" },
          { name: "APAC Spot", value: "APAC Spot" },
          { name: "APAC M+1", value: "APAC M+1" }
        ]}
      />
      <p>Maturities</p>
      <FormContext.Consumer>
        {(ctx) => {
          const isSpot =
            ctx.data &&
            ctx.data.delivery_months &&
            ctx.data.delivery_months.length &&
            ctx.data.delivery_months[0] === "Spot";

          const isMaturityBenchmark =
            ctx.data &&
            ctx.data.benchmark &&
            ctx.data.benchmark.length &&
            ctx.data.benchmark[0].value === "Maturity";

          return (
            <>
              {trades.map((trade, key) => {
                return (
                  <TradeInput
                    key={(isSpot ? "spot." : "future.") + trade.id}
                    id={key}
                    remove={remove(key)}
                    report={report(key)}
                    data={initialTrades[key] ? initialTrades[key].data : {}}
                    maturities={filterExistingMaturities(
                      (isSpot
                        ? tradeMaturities.slice(0, 1)
                        : tradeMaturities.slice(1)
                      ).map((maturity) => {
                        return {
                          value: maturity.id,
                          name: capitalize(maturity.shortened_string),
                        };
                      })
                    )}
                  />
                );
              })}
              {isSpot || isMaturityBenchmark || (ctx.data.type && ctx.data.type[0]["name"] === 'Limit order') ? null : (
                <Button
                  onClick={() => {
                    defaultTrade.id = getTime()
                    setTrades([...trades, defaultTrade]);
                  }}
                  icon="add"
                  minimal
                >
                  Add maturity
                </Button>
              )}
            </>
          );
        }}
      </FormContext.Consumer>
    </>
  );
}
  ;
const NewRequestForm = () => {
  const { user } = useContext(AppContext);
  const { requestId } = useParams();

  useLogin(true)

  const [{ data: request, requestLoading }, refetch] = useAxios({
    url: url("/request/" + requestId + "/"),
  });

  /** Common fields */
  const [{ loading: salesEntitiesLoading, data: salesEntities }] = useAxios({
    url: url("/sales_entity/"),
  });

  const [{ loading: customersLoading, data: customers }] = useAxios({
    url: url("/customer/"),
  });

  const [{ loading: internalPortfolioLoading, data: internalPortfolio }] = useAxios({
    url: url("/internal_portfolio/"),
  });

  const [{ loading: currenciesLoading, data: currencies }] = useAxios({
    url: url("/currency/"),
  });

  const commonLoading =
    customersLoading ||
    salesEntitiesLoading ||
    currenciesLoading ||
    requestLoading;

  const loading = commonLoading || !user || internalPortfolioLoading;

  const history = useHistory();

  const [{ loading: updateLoading }, updateRequest] = useAxios(
    {
      url: "",
      method: "PUT",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const send = async (formValues, formState) => {
    const data = {
      ...formValues,
      update_request: true
    };

    data.request_type = data.type;

    delete data?.action;
    delete data?.quantity;
    delete data?.type;
    delete data?.maturity;
    if (formState.data?.contract_type?.[0]?.value) {
      data.contract_type = formState.data.contract_type[0].value;
    }
    if (formValues.instrument) {
      data.instruments = formValues.instrument.split(":::");
    }

    delete data?.instrument;
    data.trades = (formState.data.trades || []).map((trade) => {
      const maturity = {
        action: trade.action[0].value,
        maturity: trade.maturity[0].value,
        quantity: trade.quantity,
      };

      if (trade.maturity_id) {
        maturity.maturity_id = trade.maturity_id
      }

      return maturity
    });

    try {
      await updateRequest({
        url: url(`request/${requestId}/`),
        data,
      });
      history.push(`/aluminium/request/${requestId}/details`);
      Toaster.show({
        message: "The request has been edited successfully.",
        icon: "small-tick",
        intent: "success",
      });
    } catch (err) {
      const defaultError = "An error occurred while editing the request."
      Toaster.show({
        message: (err.response && err.response.data && err.response.data.error.message || err.response.data.error) || defaultError,
        icon: "warning-sign",
        intent: "danger",
      });
    }
    refetch()
  };

  return loading ? (
    "Loading..."
  ) : (
    <Form onSubmit={send}>
      <h1>Edit request</h1>
      <hr />
      <Column>
        <h2>Request details</h2>
        <InputGroup
          required
          name="type"
          type="select"
          title="Type"
          disabled={true}
          defaultValue={[request.request_type]}
          items={types.map((item) => {
            return {
              name: replaceOrderTypes(item.name),
              value: item.value,
              url: item.url,
            };
          })}
        />
        <InputGroup
          required
          type="select"
          title="Sales entity"
          name="sales_entity"
          items={salesEntities.map((item) => {
            return {
              name: item.name,
              value: item.id,
            };
          })}
          defaultValue={[request.sales_entity.id]}
        />
        <InputGroup
          required
          name="customer"
          type="select"
          title="Customer/Supplier"
          items={customers.map((item) => {
            return {
              name: item.display_name,
              value: item.id,
            };
          }).sort((a, b) => a.name.localeCompare(b.name))}
          defaultValue={[request.customer.id]}
        />
        <FormContext.Consumer>
          {(ctx) => {
            return (
              <>
              <InputGroup
                required
                type="select"
                name="currency"
                title="Currency"
                key={
                  "currency." +
                  (ctx.data.type && ctx.data.type.length
                    ? ctx.data.type[0].value
                    : "")
                }
                defaultValue={[request.currency]}
                items={currencies
                  .map((item) => {
                    return {
                      name: `${item[1]}`.toUpperCase(),
                      value: item[0],
                    };
                  })
                  .filter((item) => {
                    if (
                      ctx.data.type &&
                      ctx.data.type.length &&
                      ctx.data.type[0].value === "APC"
                    )
                      return item.value === "USD";
                    return true;
                  })}
              />
              {ctx.data.sales_entity && ctx.data.sales_entity.length && ctx.data.sales_entity[0].name === 'RT Mktg Pte Ltd PM' &&
                <InputGroup
                  required
                  name="internal_portfolio"
                  type="select"
                  title="Internal Portfolio"
                  items={internalPortfolio.map((item) => {
                    return {
                      name: item[0],
                      value: item[0],
                    };
                  })}
                  defaultValue={[request.internal_portfolio]}
                />
              }
            </>
            );
          }}
        </FormContext.Consumer>
      </Column>
      <hr />
      <Column>
        <h2>Pricing instructions</h2>
        <FormContext.Consumer>
          {(ctx) => {
            const data = ctx.data;
            return data.type && data.type.length ? (
              <>
                <TypeAwareNewRequestForm
                  type={data.type[0].value}
                  request={request}
                />
              </>
            ) : (
              <p>
                Select an order type before proceeding with pricing
                instructions.
              </p>
            );
          }}
        </FormContext.Consumer>
      </Column>
      <hr />
      <Column>
        <h2>Additional</h2>
        <RTFormGroup label="Comments">
          <TextArea style={{ width: '655px' }} growVertically={true} name="comments"
            defaultValue={request.comments}></TextArea>
        </RTFormGroup>
      </Column>
      <FormFooter>
        <Button
          onClick={() => {
            history.push(`/aluminium/request/${requestId}/details/`);
          }}
          text="Cancel"
          minimal
          large
        />
        <FormContext.Consumer>
          {({ valid }) => {
            return (
              <Button
                large
                loading={updateLoading}
                disabled={!valid}
                type="submit"
                text="Edit request"
                intent="primary"
              />
            );
          }}
        </FormContext.Consumer>
      </FormFooter>
      <div style={{ display: "block", height: "32px" }}></div>
    </Form>
  );
}

const NewRequest = () => {
  return <NewRequestForm />;
}
  ;
export default NewRequest;
