import {Table} from "rt-design-system-backup";

import columns from "./columns";
import {css} from '@emotion/css'
import React from "react";


const tableCSS = css({
    'thead > tr > th': {
        verticalAlign: 'top',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#000000',
    },
    'thead > tr > th:nth-child(5), thead > tr > th:nth-child(6)': {
        verticalAlign: 'top',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#000000',
        backgroundColor: '#b2b2b2',
    },
    'tbody > tr > td': {
        fontSize: '14px',
        lineHeight: '22px',
        color: '#000000',
    },
    'tbody > tr > td:nth-child(5), tbody > tr > td:nth-child(6)': {
        fontSize: '14px',
        lineHeight: '22px',
        color: '#000000',
        backgroundColor: '#eaeaea',
    }
});

const RequestMaturityPrices = ({request, editable}) => {
    const maturities = request.request_maturities.map((requestMaturity) => {

        let LMETrade = requestMaturity.trades.find((trade) => {
            return trade.instrument.name === "LME Aluminium";
        });

        if (!LMETrade) LMETrade = requestMaturity.trades[0];

        const OtherTrade = requestMaturity.trades.find((trade) => {
            return trade.instrument.name !== "LME Aluminium";
        });

        const prefill_mkp = editable && ["Quote", "At Market"].includes(request.request_type)


        return {
            lme_trade_id: LMETrade.id,
            mkp_trade_id: OtherTrade && OtherTrade.id,
            maturity: requestMaturity.maturity.shortened_string,
            action: requestMaturity.trades[0].action,
            quantity: requestMaturity.trades[0].quantity,
            lme_adjusted_usd: LMETrade.price,
            lme_adjusted_currency: LMETrade.price_currency,
            lme_qt: LMETrade.qt_id,
            mkp_adjusted_usd: OtherTrade && ((OtherTrade.price && parseFloat(OtherTrade.price).toFixed(2)) || (prefill_mkp && OtherTrade.compute_mw_premium ? parseFloat(OtherTrade.compute_mw_premium).toFixed(2) : '')),
            mkp_adjusted_currency: OtherTrade && OtherTrade.price_currency && parseFloat(OtherTrade.price_currency).toFixed(2),
            mkp_qt: OtherTrade && OtherTrade.qt_id,
            total_price_usd: requestMaturity.total_price,
            total_price_currency: requestMaturity.total_price_currency,
            currency_forward_rate: LMETrade.forward_rate,
            accounting_period_closed: requestMaturity.trades[0].is_accounting_period_closed,
            final_price: requestMaturity.trades[0].final_price,
        };
    });

    return (
        <>
            <h4>Maturity prices</h4>
            <div style={{position: "relative"}}>
                <div style={{display: "inline-block", maxWidth: "100%"}}>
                    <Table
                        pagination={false}
                        dataSource={maturities}
                        className={tableCSS}
                        scroll={{
                            x: true,
                        }}
                        size="small"
                        columns={columns(request)}
                    />
                </div>
            </div>
            {request.request_maturities[0].trades[0].csp_average_price != null && 
            (<div>CSP Average ($/mt): {parseFloat(request.request_maturities[0].trades[0].csp_average_price).toFixed(2)}</div>)}
        </>
    );
};

export default RequestMaturityPrices;
