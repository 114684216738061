import React, {useState, useContext, useEffect} from "react"
import useAxios from "axios-hooks"

import { Button, Tooltip, Icon, DateRangeInput } from "rt-design-system-backup"
import Page from "../../Page"
import OrdersTable from "../../OrdersTable"
import OrderCreateDialog from "../../OrderCreateDialog"
import SortContext, { defaultSortContext } from "../../SortContext"
import AppContext from "../../AppContext"
import Select from "../../Select"

import { formatSort, url, hasPerm, report } from "../../../helpers"
import axios from "axios";
import FileDownload from "js-file-download";
import {Link, useHistory} from "react-router-dom";

const Orders = () => {
    const [isOpen, setOpen] = useState(false)
    const history = useHistory();
    const { user } = useContext(AppContext)
    const getDefaultDates = () => {
        const endDate = new Date(); // Today
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1); // One month ago
        return { startDate, endDate };
    }

    const sortContext = { 
        ...defaultSortContext,
        ...getDefaultDates()
    }
    
    const [sortState, setSortState] = useState(sortContext)
    const [appliedSortState, setAppliedSortState] = useState(sortContext)

    const downloadCsv = (e) => {
        e.preventDefault()
        report('orders_download')
        axios({
            url: url("/ordercsv/" + formatSort(appliedSortState)),
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, 'orders.csv');
        });
    }

    const handleNewRange = ([startDate, endDate]) => {
        setSortState({
            ...sortState,
            startDate,
            endDate
        })
    }

    useEffect(() => {
        setAppliedSortState(sortState)
    }, [sortState]);

    const [{ data }] = useAxios(
        {
            url: url('team/'),
        }
    )

    const sizes = data ? data : []

    return <SortContext.Provider value={appliedSortState}>
        <Page title="Orders" tabs={[]}
            leftActions={
                hasPerm(user, 'rtcmdmodels.add_order') ? [
                    <Button onClick={() => history.push('/order/new')} intent="primary" text="New order" />
                ] : []
            }

            rightActions={
                [
                    <span>
                        <DateRangeInput
                        singleMonthOnly
                        allowSingleDayRange
                        defaultValue={[sortState.startDate, sortState.endDate]}
                        {...{
                            formatDate: (date) => (date == null ? '' : new Date(new Date(new Date(date.setHours(1)).setMinutes(0)).setSeconds(0)).toLocaleDateString(navigator.language)),
                            parseDate: (str) => new Date(Date.parse(str)),
                        }} popoverProps={{
                            minimal: true
                        }} value={[sortState.startDate, sortState.endDate]} onChange={handleNewRange} />
                    </span>,
                    <span>
                        <Select onChange={([filter]) => {
                            setSortState({
                                ...sortState,
                                desk: filter.value
                            })
                        }} defaultValue={["undefined"]} items={[
                            {
                                name: "All desks",
                                value: "undefined"
                            },
                            ...sizes.map((item) => {
                                return {
                                    name: item.team,
                                    value: item.team
                                }
                            }),
                            {
                                name: "My orders only",
                                value: "my_orders"
                            }
                        ]} />
                    </span>,
                    <Tooltip intent="primary" content="Download Orders">
                        <a onClick={downloadCsv} href="#0" download >
                            <span className="rtcmd-page-action-icon">
                                <Icon icon="arrow-down" />
                            </span>
                        </a>
                    </Tooltip>,
                    <Link to={"/orders/trades"}>
                        <span style={{ fontSize: '16px', fontWeight: 'normal', marginLeft: 12 }}><u>View trades</u></span>
                    </Link>
                ]
            }
        >
            <OrderCreateDialog isOpen={isOpen} onClose={() => setOpen(false)} />
            <OrdersTable />
        </Page>
    </SortContext.Provider>
}

export default Orders
